import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'react-jss';
import cpn from '../../content/documents/cpn.pdf';
import iabs from '../../content/documents/iabs.pdf';
import eohLogo from '../../images/eq-opp-housing.svg';
import realtorMLSLogo from '../../images/realtor-mls.svg';
import FooterColumn from './FooterColumn';
import LinkListComponent from './LinkList';

const styles = {
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  container: {
    flex: '1',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-around',
    padding: '30px 20px 20px 20px',
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    color: '#cccccc',
  },
  column: {
    flex: 1,
    marginBottom: 15,
    maxWidth: 200,
  },
  heading: {
    marginBottom: '10px',
    color: 'white',
  },
  disclosureLink: {
    fontSize: 14,
  },
  thirdPartyLogos: {
    marginBottom: '0px',
    height: '75px',
  },
  copyrightBar: {
    height: '100%',
    margin: '0px 0px',
    textAlign: 'center',
    color: '#cccccc',
  },
  badge: {
    borderRadius: '.25rem',
    display: 'inline-block',
    fontFamily: 'adelle, serif',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '1',
    marginBottom: '0px',
    padding: '.25em .4em',
    textAlign: 'center',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
  },
  copyright: {
    margin: '0',
    paddingBottom: '10px',
    fontSize: '14px',
  },
  '@media only screen and (max-width: 440px)': {
    container: {
      flexFlow: 'column !important',
      maxWidth: '100% !important',
      textAlign: 'center',
    },
    column: {
      maxWidth: '100% !important',
      textAlign: 'center !important',
    },
  },
};

const query = graphql`
  query footerQuery {
    componentJson(component: { eq: "footer" }) {
      pages {
        label
        to
      }
      socialPlatforms {
        label
        to
      }
      message {
        heading
        paragraph
      }
      textBadge
      copyright
    }
  }
`;

const Footer = ({ classes }) => (
  <StaticQuery
    query={query}
    render={({
      componentJson: { pages, socialPlatforms, message, textBadge, copyright },
    }) => (
      <footer className={classes.root}>
        <div className={classes.container}>
          <FooterColumn heading="Pages">
            <LinkListComponent className={classes.label} links={pages} />
          </FooterColumn>
          <FooterColumn heading="Social">
            <LinkListComponent
              className={classes.label}
              heading="Social"
              links={socialPlatforms}
            />
          </FooterColumn>
          <FooterColumn heading={message.heading}>
            <p className={classes.label}>
              {message.paragraph}{' '}
              <a
                href={iabs}
                title="Information about Brokerage Services"
                className={classes.disclosureLink}
                target="blank"
              >
                Info about Brokerage Services.
              </a>
              <br />
              <a
                href={cpn}
                title="Consumer Protection Notice"
                className={classes.disclosureLink}
                target="blank"
              >
                Consumer Protection Notice.
              </a>
            </p>
          </FooterColumn>
        </div>
        <div className={classes.copyrightBar}>
          <span className={classes.badge}>{textBadge}</span>
          <div>
            <img
              className={classes.thirdPartyLogos}
              src={eohLogo}
              alt="Equal Housing Opportunity Logo"
            />
            <img
              className={classes.thirdPartyLogos}
              src={realtorMLSLogo}
              alt="Realtor MLS Logo"
            />
          </div>
          <p className={classes.copyright}>{copyright}</p>
        </div>
      </footer>
    )}
  />
);

Footer.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(Footer);

import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import PropTypes from 'prop-types';
import React from 'react';
import icon from '../../images/dsp_marker.png';
import mapStyle from './mapStyle';

export class MapContainerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: true,
      activeMarker: {},
      // selectedPlace: {},
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClicked = this.onMapClicked.bind(this);
  }

  onMarkerClick(props, marker) {
    this.setState({
      // selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  onMapClicked() {
    const { showingInfoWindow } = this.state;

    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  }

  render() {
    const { google } = this.props;
    const { activeMarker, showingInfoWindow } = this.state;

    return (
      <Map
        google={google}
        initialCenter={{
          lat: 32.73,
          lng: -97.375,
        }}
        zoom={14}
        onClick={this.onMapClicked}
        style={{ height: '100%' }}
        styles={mapStyle}
      >
        <Marker
          onClick={this.onMarkerClick}
          title="D. Solis Properties"
          name="D. Solis Properties"
          position={{ lat: 32.7247, lng: -97.38128 }}
          icon={{
            url: icon,
          }}
        />
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <div>
            <h1 style={{ marginBottom: 8, fontSize: 16 }}>
              D. Solis Properties
            </h1>
            <p>
              4330 Vickery Blvd. <br /> Fort Worth, TX 76107
            </p>
            <a href="https://www.google.com/maps/@32.7245849,-97.381481,20z">
              Get Directions
            </a>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

MapContainerComponent.propTypes = {
  google: PropTypes.shape({
    maps: PropTypes.object,
  }).isRequired,
};

const MapContainer = GoogleApiWrapper({
  apiKey: `${process.env.GMAPS_API_KEY}`,
})(MapContainerComponent);

export default MapContainer;

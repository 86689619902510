import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';
import withStyles from 'react-jss';

const styles = {
  carouselContainer: {
    width: '100%',
    margin: '0 0 0 0',
    padding: '0',
    maxWidth: 'none',
  },
  carouselControl: {
    display: 'none',
  },
  carouselItem: {
    transitionDuration: '2s !important',
    height: '90vh',
  },
  heroImage: {
    height: '100%',
  },
  '@media only screen and (min-width: 1170px)': {
    carouselItem: {
      maxHeight: 540,
    },
  },
  '@media only screen and (min-width: 970px) and (max-width: 1169px)': {
    carouselItem: {
      maxHeight: 540,
    },
  },
  '@media only screen and (max-width: 970px)': {
    carouselItem: {
      height: '90vh',
      maxHeight: 510,
    },
  },
};

class HeroCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { heroImages } = this.props;
    const { activeIndex } = this.state;

    if (this.animating) return;
    const nextIndex =
      activeIndex === heroImages.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { heroImages } = this.props;
    const { activeIndex } = this.state;

    if (this.animating) return;
    const nextIndex =
      activeIndex === 0 ? heroImages.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { heroImages, classes } = this.props;

    const slides = heroImages.map(image => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={image.node.id}
          className={classes.carouselItem}
          style={{ textAlign: 'center', color: 'white', height: '100%' }}
        >
          <Img
            title=""
            alt=""
            className={classes.heroImage}
            fluid={image.node.childImageSharp.fluid}
          />
        </CarouselItem>
      );
    });

    return (
      <div className={classes.carouselContainer}>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          className="carousel-fade"
          ride="carousel"
        >
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
            cssModule={{ 'carousel-control-prev': classes.carouselControl }}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
            cssModule={{ 'carousel-control-next': classes.carouselControl }}
          />
        </Carousel>
      </div>
    );
  }
}

HeroCarousel.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  heroImages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(HeroCarousel);

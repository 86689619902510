import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

const ServiceCard = ({ icon, service, desc }) => (
  <Card style={{ marginTop: 75, minWidth: 250, border: 'none' }}>
    <img
      src={icon}
      alt={`${service} Icon`}
      style={{ width: '100%', height: 85, color: 'rgb(140, 163, 111)' }}
    />
    <CardBody style={{ textAlign: 'center' }}>
      <CardTitle style={{ fontSize: 20 }}>{service}</CardTitle>
      <CardText>{desc}</CardText>
    </CardBody>
  </Card>
);

ServiceCard.propTypes = {
  icon: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default ServiceCard;

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        name
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultLogo: logo
        telephone
        facebook
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
    }
  }
`;

const SEO = ({ title, description, image, pathname, type, corporation }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          name,
          defaultDescription,
          siteUrl,
          defaultImage,
          defaultLogo,
          telephone,
          facebook,
          streetAddress,
          addressLocality,
          addressRegion,
          postalCode,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        name,
        image: `${siteUrl}${image || defaultImage}`,
        logo: `${siteUrl}${defaultLogo}`,
        url: `${siteUrl}${pathname || '/'}`,
        telephone,
        facebook,
        address: {
          street: streetAddress,
          locality: addressLocality,
          region: addressRegion,
          zip: postalCode,
        },
      };

      const corporationJsonLD = () => {
        return (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Corporation',
              name: seo.name,
              url: seo.url,
              logo: seo.logo,
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: seo.telephone,
                contactType: 'customer service',
                areaServed: 'US',
              },
              sameAs: seo.facebook,
              address: {
                '@type': 'PostalAddress',
                streetAddress: seo.address.street,
                addressLocality: seo.address.locality,
                addressRegion: seo.address.region,
                postalCode: seo.address.zip,
                addressCountry: 'United States',
              },
            })}
          </script>
        );
      };

      const webpageJsonLD = () => {
        return (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: seo.url,
              name: seo.title,
              description: seo.description,
            })}
          </script>
        );
      };

      return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
          {/* General Tags */}
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />

          {/* OpenGraph Tags */}
          {seo.url && <meta property="og:url" content={seo.url} />}
          {<meta property="og:type" content={type} />}
          {seo.title && <meta property="og:title" content={seo.title} />}
          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {seo.image && <meta property="og:image" content={seo.image} />}

          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          {seo.title && <meta name="twitter:title" content={seo.title} />}
          {seo.description && (
            <meta name="twitter:description" cnotent={seo.description} />
          )}
          {seo.image && <meta name="twitter:image" content={seo.image} />}

          {/* Structured Data */}
          {webpageJsonLD()}
          {corporation ? corporationJsonLD() : null}
        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  type: PropTypes.string,
  corporation: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  type: null,
  corporation: false,
};

export default SEO;

import fetch from 'isomorphic-fetch';
import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px auto',
    width: '90vw',
    maxWidth: 960,
  },
  inputContainer: {
    display: 'flex',
    flex: '0 0 50%',
    flexDirection: 'column',
    padding: '10px 15px',
    minWidth: 320,
  },
  input: {
    borderStyle: 'solid',
    borderColor: '#6e6d75',
    borderWidth: 1,
    borderRadius: 3,
    boxShadow: 'none',
    height: 40,
    textIndent: 5,
    transition: '0.25s background-color',
    '&::placeholder': {
      fontSize: 14,
    },
    '&:focus': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      backgroundColor: 'rgba(110, 109, 117, 0.1)',
    },
    '&:required': {
      boxShadow: 'none',
    },
    '&:invalid': {
      boxShadow: 'none',
    },
  },
  formButton: {
    display: 'flex',
    flex: '0 0 100%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px auto',
    padding: '10px 15px',
  },
  formButtonInput: {
    borderStyle: 'solid',
    borderColor: '#6e6d75',
    borderWidth: 1,
    borderRadius: 3,
    boxShadow: 'none',
    height: 40,
    textIndent: 5,
    backgroundColor: '#8ca36f',
    color: 'white',
    transition: '0.5s background-color',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#6e6d75',
    },
  },
  '@media only screen and (max-width: 712px)': {
    inputContainer: {
      flex: '0 0 100%',
    },
  },
};

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => this.setState({ success: true }))
      // eslint-disable-next-line no-undef
      .catch(error => alert(error));
  }

  render() {
    const { success } = this.state;
    const { classes } = this.props;

    return (
      <form
        name="contact"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className={classes.formContainer}
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="bot-field" onChange={this.handleChange} />
        <div className={classes.inputContainer}>
          <label htmlFor="fname">First Name*</label>
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="Required"
            className={classes.input}
            style={{
              backgroundColor: `${success ? '#e0e0e0' : 'white'}`,
            }}
            onChange={this.handleChange}
            readOnly={success}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="lname">Last Name*</label>
          <input
            type="text"
            name="lname"
            id="lname"
            placeholder="Required"
            className={classes.input}
            onChange={this.handleChange}
            style={{
              backgroundColor: `${success ? '#e0e0e0' : 'white'}`,
            }}
            readOnly={success}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="email">Email*</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Required"
            className={classes.input}
            style={{
              backgroundColor: `${success ? '#e0e0e0' : 'white'}`,
            }}
            onChange={this.handleChange}
            readOnly={success}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="Optional"
            className={classes.input}
            style={{
              backgroundColor: `${success ? '#e0e0e0' : 'white'}`,
            }}
            onChange={this.handleChange}
            readOnly={success}
          />
        </div>
        <div className={classes.inputContainer} style={{ flex: '1 0 50%' }}>
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            style={{
              height: 100,
              padding: 5,
              backgroundColor: `${success ? '#e0e0e0' : 'white'}`,
            }}
            className={classes.input}
            placeholder="Required. Breifly describe your inquiry or the services your are looking for."
            onChange={this.handleChange}
            readOnly={success}
            required
          />
        </div>
        <div className={classes.formButton}>
          <input
            type="submit"
            id="submit"
            value={success ? 'Success!' : 'Submit'}
            style={{ width: 200, height: 40 }}
            readOnly={success}
            className={classes.formButtonInput}
          />
          <label htmlFor="submit" style={{ marginTop: 10 }} hidden={!success}>
            {"Thank you! We'll get back with you shortly!"}
          </label>
        </div>
      </form>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(ContactForm);

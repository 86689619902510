import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'react-jss';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';

const styles = {
  carouselContainer: {
    margin: '0',
    width: '100%',
    maxWidth: 'none',
    padding: '0',
    textAlign: 'center',
  },
  testimonial: {
    display: 'flex',
    flexDirection: 'column',
    height: '250px',
    textAlign: 'center',
  },
  statement: {
    padding: '0px 10px 0px 10px',
  },
  name: {
    marginTop: 'auto',
  },
  '@media only screen and (min-width: 1170px)': {
    testimonial: {
      height: 160,
    },
  },
  '@media only screen and (min-width: 970px) and (max-width: 1169px)': {
    testimonial: {
      maxHeight: 160,
      padding: '0 20px 0 20px',
    },
  },
  '@media only screen and (min-width: 577px) and (max-width: 969px)': {
    testimonial: {
      height: 180,
      padding: '0 20px 0 20px',
    },
  },
  '@media only screen and (max-width: 576px)': {
    testimonial: {
      height: 250,
      padding: '0 20px 0 20px',
    },
  },
};

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { activeIndex } = this.state;
    const { slides } = this.props;

    if (this.animating) {
      return;
    }
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { activeIndex } = this.state;
    const { slides } = this.props;

    if (this.animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { slides, classes } = this.props;

    const slideComponents = slides.map(({ statement, name }) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={statement}
        >
          <div className={classes.testimonial}>
            <p className={classes.statement}>{statement}</p>
            <h4 className={classes.name}>{name}</h4>
          </div>
        </CarouselItem>
      );
    });

    return (
      <div className={classes.carouselContainer}>
        <style />
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          ride="carousel"
          interval={10000}
        >
          {slideComponents}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}

Testimonials.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(Testimonials);

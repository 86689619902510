import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'react-jss';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import logo from '../images/fw-wo-banner.svg';

const styles = {
  navImg: {
    marginBottom: '0px',
  },
  navbarBrand: {
    color: 'white',
    width: '50%',
    maxWidth: '200px',
  },
  navbar: {
    padding: '0.5rem 2rem !important',
  },
  navLink: {
    minWidth: '100px',
    color: '#6e6d75',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: '#8ca36f !important',
      fontWeight: 'bold',
    },
  },
  navLinkActive: {
    color: '#8ca36f !important',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navItem: {
    fontWeight: 'bold',
    marginBottom: '0px',
  },
  welcomeBar: {
    display: 'flex',
    width: '100%',
    padding: '0.25rem 2rem',
    backgroundColor: '#6e6d75',
    color: 'white',
    fontWeight: 600,
  },
  welcomeBarRightItems: {
    display: 'flex',
    marginLeft: 'auto',
  },

  welcomeBarRightItemsP: {
    paddingLeft: '15px',
    marginBottom: 0,
  },
  welcomeBarP: {
    margin: '0',
    fontSize: '14px',
  },
  welcomeBarSvg: {
    marginRight: '5px',
    color: '#8ca36f',
  },
  '@media only screen and (max-width: 768px)': {
    welcomeBar: {
      display: 'none !important',
    },
  },
};

export class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: true };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState(state => ({ collapsed: !state.collapsed }));
  }

  render() {
    const { collapsed } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: 'white' }}>
        <div className={classes.welcomeBar}>
          <p className={classes.welcomeBarP}>
            <FontAwesomeIcon
              className={classes.welcomeBarSvg}
              icon={faHome}
              style={{ height: 14 }}
            />
            Welcome to D. Solis Properties
          </p>
          <div className={classes.welcomeBarRightItems}>
            <p className={classes.welcomeBarRightItemsP}>
              <FontAwesomeIcon
                className={classes.welcomeBarSvg}
                icon={faEnvelope}
                style={{ height: 14 }}
              />
              <span>dsolisproperties</span>
              <span>@gmail.com</span>
            </p>
            <p className={classes.welcomeBarRightItemsP}>
              <FontAwesomeIcon
                className={classes.welcomeBarSvg}
                icon={faPhone}
                style={{ height: 14 }}
              />
              (682) 365-8571
            </p>
          </div>
        </div>
        <Navbar light expand="sm" role="navigation">
          <Link className={classes.navbarBrand} to="/">
            <img
              src={logo}
              alt="D. Solis Properties Logo"
              className={`d-none d-md-block d-lg-block d-xl-block ${
                classes.navImg
              }`}
              style={{ height: 77 }}
            />
            <h1
              className="d-md-none"
              style={{
                marginBottom: 0,
                fontSize: '18px',
                color: '#6e6d75',
                width: '100%',
              }}
            >
              D. Solis Properties
            </h1>
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className={classes.navItem}>
                <Link
                  className={`nav-link ${classes.navLink}`}
                  activeClassName={classes.navLinkActive}
                  to="/"
                >
                  Home
                </Link>
              </NavItem>
              <NavItem className={classes.navItem}>
                <Link
                  className={`nav-link ${classes.navLink}`}
                  activeClassName={classes.navLinkActive}
                  to="/about/"
                >
                  About
                </Link>
              </NavItem>
              <NavItem className={classes.navItem}>
                <Link
                  className={`nav-link ${classes.navLink}`}
                  activeClassName={classes.navLinkActive}
                  to="/contact/"
                >
                  Contact
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const Header = withStyles(styles)(HeaderComponent);

export default Header;

import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'react-jss';
import stylePropType from 'react-style-proptype';
import Link from './Link';

const styles = {
  button: {
    display: 'inline-block',
    border: '1px solid rgba(140, 163, 111, 1)',
    borderRadius: 3,
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s',
    backgroundColor: props =>
      props.translucent ? 'rgba(110, 109, 117, .75)' : 'rgba(110, 109, 117, 1)',
    '&:hover': {
      borderColor: 'rgba(110, 109, 117, 1) !important',
      backgroundColor: 'rgba(140, 163, 111, 1) !important',
    },
  },
  primaryButton: {
    display: 'inline-block',
    border: '1px solid rgba(140, 163, 111, 1)',
    borderRadius: 3,
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s',
    backgroundColor: props =>
      props.translucent ? 'rgba(140, 163, 111, .75)' : 'rgba(140, 163, 111, 1)',
    '&:hover': {
      borderColor: 'rgba(140, 163, 111, 1) !important',
      backgroundColor: 'rgba(110, 109, 117, 1) !important',
    },
  },
};

const Button = ({
  to,
  style,
  text,
  primary,
  translucent,
  classes,
  ...other
}) => {
  return (
    <div
      style={style}
      className={primary ? classes.primaryButton : classes.button}
      {...other}
    >
      <Link
        to={to}
        style={{ display: 'block', color: 'white', height: 50, width: 150 }}
      >
        {text}
      </Link>
    </div>
  );
};

Button.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  style: stylePropType,
  primary: PropTypes.bool,
  translucent: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Button.defaultProps = {
  style: {},
  primary: false,
  translucent: false,
};

export default withStyles(styles)(Button);

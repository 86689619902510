import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

export const Wide = ({ className, style, children }) => (
  <div className={className} style={style}>
    <div
      className={className}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        height: 'inherit',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  </div>
);

Wide.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: stylePropType,
  children: PropTypes.node.isRequired,
};

Wide.defaultProps = {
  style: {},
  className: '',
};

export default Wide;

import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'react-jss';

const styles = {
  partnerCardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    margin: '50px',
  },
  partnerCard: {
    textAlign: 'center',
    margin: '5px 20px',
    maxWidth: '260px',
  },
  partnerCardH3: {
    margin: '0 0 15px 0',
  },
};

const PartnerCard = ({ classes }) => (
  <div className={classes.partnerCardContainer}>
    <div className={classes.partnerCard}>
      <h3 className={classes.partnerCardH3}>Trinity Title of Texas</h3>
      <p>
        4325 S. Hulen <br />
        Fort Worth, TX 76109 <br />
        (817) 294-6474 <br />
        <strong>Contact: Lori Criss</strong>
      </p>
    </div>
    <div className={classes.partnerCard}>
      <h3 className={classes.partnerCardH3}>Guild Mortgage</h3>
      <p>
        6115 Camp Bowie Blvd #144 <br />
        Fort Worth, TX 76116 <br />
        (817) 723-8993 <br />
        <strong>Contact: Helene Springer</strong>
      </p>
    </div>
  </div>
);

PartnerCard.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withStyles(styles)(PartnerCard);

import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'react-jss';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '50px auto',
    width: '90%',
    alignItems: 'center',
    alignContent: 'center',
  },
  portrait: {
    position: 'relative',
    flex: '1',
    flexWrap: 'wrap',
    margin: '0 auto',
    borderWidth: '6px',
    borderStyle: 'solid',
    borderColor: '#8ca36f',
    width: '100%',
    maxWidth: '320px',
    minWidth: '250px',
  },
  bio: {
    flex: '2',
    margin: '0 0 0 5%',
    minWidth: '250px',
  },
  heading: {
    margin: '20px 0 10px 0',
  },
  '@media only screen and (max-width: 577px)': {
    portrait: {
      flex: '0 0 90%',
      margin: '20px auto',
      textAlign: 'center',
    },
    bio: {
      flex: '0 0 90%',
      margin: '20px auto',
      textAlign: 'center',
    },
  },
};

const AgentCard = ({ img, name, bio, classes }) => (
  <div className={classes.root}>
    <Img
      fluid={img.src.childImageSharp.fluid}
      alt={img.alt}
      className={classes.portrait}
    />
    <div className={classes.bio}>
      <h3 className={classes.heading}>{name}</h3>
      <p>{bio}</p>
    </div>
  </div>
);

AgentCard.propTypes = {
  bio: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  img: PropTypes.shape({
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    alt: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(AgentCard);

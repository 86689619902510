import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
  column: {
    flex: 1,
    marginBottom: 15,
    maxWidth: 200,
  },
  heading: {
    marginBottom: '10px',
    color: 'white',
  },
  '@media only screen and (max-width: 440px)': {
    column: {
      maxWidth: '100% !important',
      textAlign: 'center !important',
    },
  },
};

const FooterColumnComponent = ({ heading, classes, children }) => (
  <div className={classes.column}>
    <h4 className={classes.heading}>{heading}</h4>
    {children}
  </div>
);

FooterColumnComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
};

const FooterColumn = withStyles(styles)(FooterColumnComponent);

export default FooterColumn;

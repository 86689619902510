import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../Footer/Footer';
import Header from '../Header';
import './bootstrap.min.css';
import './Layout.scss';

const IndexLayout = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
        file(relativePath: { eq: "footer-image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2650) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={`${data.site.siteMetadata.title} | ${props.subtitle}`}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
        >
          <html lang="en" />
          {/* <link rel="stylesheet" href="https://use.typekit.net/dmf2xsf.css" /> */}
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            paddingTop: 0,
          }}
        >
          {props.children}
        </div>
        <Footer />
      </div>
    )}
  />
);

IndexLayout.propTypes = {
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
};

IndexLayout.defaultProps = {
  subtitle: '',
};

export default IndexLayout;

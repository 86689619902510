import PropTypes from 'prop-types';
import React, { Children } from 'react';
import withStyles from 'react-jss';
import Link from '../Link';
import FooterColumn from './FooterColumn';

const styles = {
  column: {
    flex: 1,
    marginBottom: 15,
    maxWidth: 200,
  },
  heading: {
    marginBottom: '10px',
    color: 'white',
  },
  label: {
    marginBottom: '10px',
    fontSize: '14px',
  },
  link: {
    color: '#cccccc',
    '&:hover': {
      color: 'white',
    },
  },
  active: {
    color: '#cccccc',
    textDecoration: 'underline',
  },
  '@media only screen and (max-width: 440px)': {
    column: {
      maxWidth: '100% !important',
      textAlign: 'center !important',
    },
  },
};

const LinkListComponent = ({ links, classes }) => (
  <div>
    {links.map(({ label, to }) => (
      <p className={classes.label} key={label}>
        <Link className={classes.link} to={to} activeClassName={classes.active}>
          {label}
        </Link>
      </p>
    ))}
  </div>
);

LinkListComponent.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const LinkList = withStyles(styles)(LinkListComponent);

export default LinkList;
